import React, {useEffect, useState} from "react";
import {FormGroup, Label, Row, CustomInput, Input} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import InformationModelEmployee from "../model/informations.model";
import {identity} from "../../../helpers/constants";
import UploadDocument from "../updateEmployee/document.upload";
import CivilityModelEmployee from "../model/civility.model";
import { AdaAddress } from "@adaming/ada-react-business-component";

export default function InformationEmployee(props) {
    const {register, handleSubmit, errors, setValue, reset} = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [upload, setUpload] = useState(false);
    const [document, setDocument] = useState({})

    const onSubmit = data => {
        console.log("in information step to Civility : ", data);
        let valid = true;
        if(data.contents && data.name && data.type && data.size && data.validityDate && data.functionalType || document.contents){
            setDocument({contents: data.contents,  name: data.name, type: data.type, size: data.size, validityDate: data.validityDate, functionalType: data.functionalType})
            setUpload(false)
        }
        /*else{
            setErrorMessage("Verifier les données de la piece d'identité")
            valid=false;
        }*/
        if(valid && (!data.firstName || !data.lastName || !data.contactInformation.email)){
            setErrorMessage("Champs obligatoire : Nom , prénom et Email")
            valid = false;
        }

        if(valid){
            props.updateAction({...data, identityDoc: document.contents ? document : {contents: data.contents,  name: data.name, type: data.type, size: data.size, validityDate: data.validityDate, functionalType: data.functionalType}});
            props.step("2");
            setErrorMessage("")
        }

    };

    const onSubmitUpload = data =>{
        console.log("PI",data)
        if(data.contents && data.name && data.type && data.size && data.validityDate && data.functionalType){
            setDocument({contents: data.contents,  name: data.name, type: data.type, size: data.size, validityDate: data.validityDate, functionalType: data.functionalType})
            setUpload(false)
        }else{
            setErrorMessage("Verifier les données ")
        }

    }

    const action = () => {
        setUpload(true)
        setErrorMessage("")
    };

    useEffect(() => {
        register({name: "address.number"});
        register({name: "address.street"});
        register({name: "address.city"});
        register({name: "address.zipCode"});
        register({name: "address.provence"});
        register({name: "address.country"});
    }, []);

    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style col-sm-12 row">
                            <div className="roe-card-body col-sm-4">
                                <div className="pl-5 pt-5 aligner-wrapper text-center">
                                    <img className="pt-5"
                                        src={identity}
                                        style={{width:"280px"}}
                                    />
                                    <div style={{marginTop:"10px"}}>
                                        <h3 className="card-title fs-25 bold-text">Coordonnées Personnelles</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="roe-card-body col-sm-8">

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>
                                            <InformationModelEmployee icon={identity}
                                                register={register}
                                                setValue={setValue}
                                                errors={errors}
                                            >

                                                
                                                <UploadDocument
                                                    setUpload={setUpload} errorMessage={errorMessage}
                                                    upload={upload}
                                                    onSubmitUpload={onSubmit}
                                                    register={register}
                                                    setValue={setValue} errors={errors}
                                                    handleSubmit={handleSubmit}
                                                />

                                            </InformationModelEmployee>
                                            <CivilityModelEmployee register={register} setValue={setValue} errors={errors}/>
                                            <AdaAddress register={register} setValue={setValue}
                                                                  errors={errors}/>

                                                <Row className="col-sm-12 pt-3">
                                                    <Col className="col-sm-1">
                                                        {document.contents && <img style={{width:"60px", height:"60px"}} src={document.contents}/>}
                                                    </Col>
                                                    <Col className="col-sm-6 pt-4">
                                                        {document && document.name ? document.name : ""}
                                                    </Col>
                                                </Row>
                                            <FormGroup>

                                                <AdaButton
                                                    className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2" onClick={() => action()}>
                                                    <i className="far fas fa-paperclip" style={{fontSize: 14}}> Joindre une piece d'identité</i>
                                                </AdaButton>

                                                <AdaButton type="submit"  className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                                                    <IntlMessages id="action.suivant.bank.information"/>
                                                </AdaButton>

                                                <AdaButton  className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right" onClick={props.cancelAction}>
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

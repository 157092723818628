import React, {useEffect, useState} from "react";
import {CustomInput, FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import InputMask from "react-input-mask";
import * as service from "../../../service/crud.service";
import {getHostByAppName, IAM_NAME, NOMENCLATURE_NAME, ORGANIZATION_NAME, EMPLOYEE_NAME} from "../../../service/host";
import AdaSelectBuilder from "../../../components/ada.select.builder";
import {GetOrganizationIdFromSession} from "../../../service/session.service";
import { getAllPersonSelect } from "../../../service/employee.service";
import {Controller} from "react-hook-form";
import { AdaAddress } from "@adaming/ada-react-business-component";
import {addressPostal} from "../../../helpers/constants";


export default function InformationUpdate({register, setValue, errors, employee, edit, dispatch, control}) {
    const [functionList, setFunctionList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);
    const [recrutementOfficerList, setRecrutementOfficerList] = useState([]);
    const [recrutementOfficerSelected, setRecrutementOfficerSelected] = useState();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [countryList, setCountryList] = useState([]);
    const [familySituationList, setFamilySituationList] = useState([]);


    const handleChangeRecrutementOfficer = selectedOption => {
        console.log("selected",selectedOption)
        if (selectedOption !== null) {
            setValue("recrutementOfficer", selectedOption.object);
        } else {
            setValue("recrutementOfficer", undefined);
        }
        setRecrutementOfficerSelected(selectedOption);
    };
    useEffect(() => {
        if(employee.recrutementOfficer){
            setRecrutementOfficerSelected({key:  employee.recrutementOfficer.id, 
                                        value : employee.recrutementOfficer.id,
                                        label : employee.recrutementOfficer.firstName + " " +employee.recrutementOfficer.firstName,
                                        object : employee.recrutementOfficer
            });
        }

     }, [recrutementOfficerList]);


    useEffect(() => {
        register({name: "firstName"}, {required: "Ce champ est obligatoire."});
        register({name: "lastName"}, {required: "Ce champ est obligatoire."});
        register({name: "recrutementOfficer"});
        register({name: "skillEmployeeType.learningType"});

        getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees/internals`, setRecrutementOfficerList)

        service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `organizations`, setOrganizationList)
        service.getAllForSelect(getHostByAppName(NOMENCLATURE_NAME), `countrys`, callbackCountry)
        service.getAllForSelect(getHostByAppName(NOMENCLATURE_NAME), `marital_statuss`, setFamilySituationList)

        dispatch({"function":employee.function ? employee.function : undefined,
            "category": employee.category ? employee.category : undefined,
            "organization": employee.organization ? employee.organization : GetOrganizationIdFromSession(),
            "mutual": employee.otherDataEmployeeType && employee.otherDataEmployeeType.mutual ? employee.otherDataEmployeeType.mutual : undefined,
            "birthCountry" : employee && employee.civilState ? employee.civilState.birthCountry : undefined,
            "familySituation": employee && employee.civilState ? employee.civilState.familySituation : undefined,
            "nationalities" : employee && employee.civilState ? employee.civilState.nationalities : undefined
        })
        
               
           
        }, []);

        const callbackCountry = data =>{
            setCountryList(data)
            let tabSelected = []
            console.log("employee.civilState.nationalities",employee.civilState.nationalities)
            if(employee && employee.civilState && employee.civilState.nationalities && employee.civilState.nationalities.length > 0){
                tabSelected = data.filter(obj => employee.civilState.nationalities.includes(obj.value))
            }
            console.log("tabSelected",tabSelected)
            setValue(
                "nationalities",tabSelected
            );
        }
    
    
        const handleChangeNationality = selectedOptionTab => {
            let selectedOption = selectedOptionTab[0];
            console.log("nationality", selectedOption);
            dispatch({ nationalities : selectedOption ? selectedOption.map(obj => obj.value) : []})
        };
    return (
        <div>

            <FormGroup>
                <div>
                    <Row>
                        <Col
                            className="col-sm-2"
                            style={{"margin-top": "10px"}}
                        >
                            <CustomInput
                                id="mme"
                                type="radio"
                                name="civility"
                                value="Mme"
                                defaultChecked={employee !== undefined ? (employee.civility === "Mme" ? true : false) : true}
                                innerRef={register}
                                label={
                                    <IntlMessages id="employee.civilite.mme"/>
                                }
                            />

                            <CustomInput
                                id="mr"
                                type="radio"
                                name="civility"
                                defaultChecked={employee !== undefined ? (employee.civility === "Mr" ? true : false) : false}
                                value="Mr"
                                innerRef={register}
                                label={
                                    <IntlMessages id="employee.civilite.mr"/>
                                }
                            />
                        </Col>

                        <Col
                            className="col-sm-2"
                            style={{"margin-top": "20px"}}
                        >
                            <FormGroup>
                                <CustomInput
                                    type="switch"
                                    id="enableContactClt"
                                    name="enable"
                                    defaultChecked={employee !== undefined ? employee.enable : true}
                                    innerRef={register}
                                    label={<IntlMessages id="employee.actif"/>}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.nom"/>
                        <AdaInputText
                            name="lastName"
                            defaultValue={employee !== undefined ? employee.lastName : ""}
                            errors={errors}
                            innerRef={register}
                        />
                        
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.prenom"/>
                        <AdaInputText
                            name="firstName"
                            defaultValue={employee !== undefined ? employee.firstName : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.telephone"/>
                        <AdaInputText
                            name="contactInformation.phone"
                            defaultValue={employee !== undefined && employee.contactInformation? employee.contactInformation.phone : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.contacts.mobile"/>
                        <AdaInputText
                            name="contactInformation.mobile"
                            defaultValue={employee !== undefined && employee.contactInformation ? employee.contactInformation.mobile : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.function"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_functions/`}
                                          inputName={"function"}
                                          optionsType={"string"}
                                          defaultId={employee.function}
                                          control={control}
                                          dispatch={dispatch}/>

                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.category"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_categorys/`}
                                          inputName={"category"}
                                          optionsType={"string"}
                                          defaultId={employee.category}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.skills.years"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/experience_levels/`}
                                          inputName={"experienceLevel"}
                                          optionsType={"string"}
                                          defaultId={employee.skillEmployeeType && employee.skillEmployeeType.experienceLevel ? employee.skillEmployeeType.experienceLevel : null}
                                          control={control}
                                          dispatch={dispatch}/>

                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.mutual"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/mutuals/`}
                                          inputName={"mutual"}
                                          optionsType={"string"}
                                          defaultId={employee.otherDataEmployeeType && employee.otherDataEmployeeType.mutual ? employee.otherDataEmployeeType.mutual : null}
                                          control={control}
                                          dispatch={dispatch}
                                          
                                          />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.contacts.email"/>
                        <AdaInputText
                            name="contactInformation.email"
                            defaultValue={employee !== undefined && employee.contactInformation ? employee.contactInformation.email : ""}
                            type="email"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.learning.type"/>
                        <AdaInputText
                            name="skillEmployeeType.learningType"
                            defaultValue={employee !== undefined && employee.skillEmployeeType ? employee.skillEmployeeType.learningType : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
               
            </Row>
            <Row>
                
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.recrutement.officer"/>
                       


                        <AdaSelect
                        
                            name="recrutementOfficer"
                            value={recrutementOfficerSelected}
                            errors={errors}
                            isClearable
                            options={recrutementOfficerList}
                            onChange={handleChangeRecrutementOfficer}
                            innerRef={register}


                        />

                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.security.number"/>
                        <AdaInputText
                            type="text"
                            name="socialSecurity.socialSecurityNumber"
                            defaultValue={employee !== undefined && employee.socialSecurity ? employee.socialSecurity.socialSecurityNumber : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.security.membership.date"/>
                        <AdaInputText
                            type="date"
                            name="socialSecurity.membershipDate"
                            defaultValue={employee !== undefined && employee.socialSecurity ? employee.socialSecurity.membershipDate : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>

                <Col>
                    <FormGroup>
                        <IntlMessages id="medical.date"/>
                        <AdaInputText
                            type="date"
                            name="otherDataEmployeeType.medicalExaminationDate"
                            defaultValue={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.medicalExaminationDate : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>

                <Col >
                    <FormGroup>
                        <IntlMessages id="cityMobility"/>
                        <AdaInputText
                            type="text"
                            name="otherDataEmployeeType.cityMobility"
                            defaultValue={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.cityMobility : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>

            </Row>

                <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.birthDate"/>
                        <AdaInputText
                            type="date"
                            defaultValue={employee !== undefined && employee.civilState? employee.civilState.birthDate : ""}
                            name="civilState.birthDate"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.nativeCountry"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/countrys/`}
                                          inputName={"birthCountry"}
                                          optionsType={"string"}
                                          defaultId={employee.civilState ? employee.civilState.birthCountry : null}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.birthCity"/>
                        <AdaInputText
                            name="civilState.birthCity"
                            defaultValue={employee !== undefined  && employee.civilState ? employee.civilState.birthCity : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.birthName"/>
                        <AdaInputText
                            name="civilState.birthName"
                            defaultValue={employee !== undefined  && employee.civilState ? employee.civilState.birthName : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.familySituation"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/marital_statuss/`}
                                          inputName={"familySituation"}
                                          optionsType={"string"}
                                          defaultId={employee.civilState? employee.civilState.familySituation : null}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.nationality"/>

                        <Controller
                            as={<AdaSelect name={"nationalities"} />}
                            name={"nationalities"}
                            isMulti
                            isClearable
                            errors={errors}
                            options={countryList}
                            onChange={handleChangeNationality}
                            control={control}
                            innerRef={register}
                        />



                    </FormGroup>
                </Col>
            </Row>

                                                        

            <AdaAddress setValue={setValue} register={register}
                        errors={errors}
                        address={employee !== undefined ? employee.address : {}}
                        edit={true}/>

            <Row>
                <Col className="mt-4">
                    <CustomInput
                        id="vehicle"
                        type="checkbox"
                        name="otherDataEmployeeType.vehicle"
                        defaultChecked={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.vehicle : false}
                        innerRef={register}
                        label={
                            "Véhicule"
                        }
                    />
                </Col>

                <Col className="mt-4">
                    <CustomInput
                        id="available"
                        type="checkbox"
                        name="otherDataEmployeeType.available"
                        defaultChecked={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.available : false}
                        innerRef={register}
                        label={
                            "Disponibilité"
                        }
                    />
                </Col>
                <Col className="mt-4">
                        <CustomInput
                            id="internal"
                            type="checkbox"
                            name="otherDataEmployeeType.internal"
                            defaultChecked={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.internal : false}
                            innerRef={register}
                            label={
                                "Interne"
                            }
                        />
                    </Col>
                    <Col className="mt-4">
                        <CustomInput
                            id="suivi"
                            type="checkbox"
                            name="otherDataEmployeeType.suivi"
                            defaultChecked={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.suivi : false}
                            innerRef={register}
                            label={
                                "Suivi"
                            }
                        />
                    </Col>

                </Row>

        </div>
    );
}
